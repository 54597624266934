@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: Avenir;
    src: url('../fonts/Avenir/Poppins-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}



:root{
    --primary-ss-color:#4fb7e3; 
    --secondary-ss-color: #272727;
    --dark-green: #08252c ;
    --pink: #f5edee ;
}

*{
    padding: 0;
    margin: 0;
}

body{
    font-family: poppins, sans-serif;
    color: black;
}

.text-poppins{
    font-family: poppins, sans-serif;
}

.text-avenir{
    font-family: Avenir, sans-serif !important;
}

.text-montserrat{
    font-family:sans-serif;
}

.text-ss-primary{
    color: var(--primary-ss-color);
}
.text-ss-secondary{
    color: var(--secondary-ss-color) !important;
}
.text-ss-black{
    color: var(--black-color);
}
.text-ss-green{
    color: #8fb53c;
}

.bg-ss-green{
    background-color: #8fb53c;
}

.bg-ss-primary{
    background-color: var(--primary-ss-color);
}
.bg-ss-secondary{
    background-color: var(--secondary-ss-color);
}

.bg-ss-pink{
    background-color: var(--pink);
}
.bg-ss-darkgreen{
    background-color: var(--dark-green);
}

.bg-ssl-primary{
    background-color: var(--primary-ssl-color);
}
.bg-ssl-secondary{
    background-color: var(--secondary-ssl-color);
}
.bg-secondary-ssl-color{
    background-color: var(--secondary-ssl-color);
}

.secondary-ssl-color{
    color: var(--secondary-ssl-color);
}

.w80px{
    width: 80px;
}

.home-services-img{
    /* width: 100%; */
    width: 100px;
    max-width: 200px;
}

.border-ss-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}

.fs-ss-92{
    font-size: 92px;
}

.fs-ss-68{
    font-size: 68px;
}

.fs-ss-64{
    font-size: 64px;
}

.fs-ss-58{
    font-size:58px;
}

.fs-ss-56{
    font-size:56px;
}
.fs-ss-52{
    font-size:52px;
}

.fs-ss-48{
    font-size: 48px;
}

.fs-ss-40{
    font-size: 40px;
}
.fs-ss-35{
    font-size: 35px;
}

.fs-ss-32{
    font-size: 32px;
}

.fs-ss-30{
    font-size: 30px;
}

.fs-ss-28{
    font-size: 28px;
}
.fs-ss-26{
    font-size: 26px;
}
.fs-ss-24{
    font-size: 24px;
}
.fs-ss-22{
    font-size: 22px;
}

.fs-ss-20{
    font-size: 20px;
}

.fs-ss-18{
    font-size: 18px;
}

.fs-ss-16{
    font-size: 16px;
}
.fs-ss-17{
    font-size: 17px;
}
.fs-ss-15{
    font-size: 15px;
}

.fs-ss-13{
    font-size: 13px;
}

.fs-ss-14{
    font-size: 14px;
}

.logo-img{
    height:60px;
}

.container-ss{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.container-ss2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .active{
    color: var(--secondary-ss-color);
}

#website-nav .dropdown-toggle{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}

.navbar-offcanvas{
    /* background-color: var(--primary-ss-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-ssl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}
.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}

.lh-1-2{
    line-height: 1.2;
}


.lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
}


.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.text-white{
    color: #ffffff;
}
.banner-section{
    position: relative;
}
.about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
}

.w80px {
    width: 80px;
}

.border-header{
    border-bottom: 4px solid var(--secondary-ss-color) !important;
}


.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    border: 0;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}

.slider-wrapper {
    width: 100%;

    margin: auto;
  }

  .btn-ss-white{
    background-color: #fff;
    color: black;
    border: 0;
    border-radius: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }

.btn-ss-white:hover{
    background-color: rgb(238, 238, 238);
    color: black;
    border: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}
.btn-ss-white:focus{
    background-color: rgb(238, 238, 238) !important;
    color: black !important;
}
.home-banner .row{
    margin: 0;
}

.spacer-ss-15{
    height: 15px;
}

.spacer-ss-25{
    height: 25px;
}

.spacer-ss-37{
    height: 37px;
}

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-ss-secondary-footer{
    background-color: var(--secondary-ss-color);
    border: none;
    color: var(--secondary-ss-color);
    height: 3px;
    opacity: 1;
    width: 100px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px;
  }

  .rounded20{
    border-radius: 20px;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-ss-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}

.btn-ss-primary{
    background-color: var(--primary-ss-color);
    color: var(--secondary-ss-color) !important;
    cursor: pointer;
    font-weight: 600;
}

.btn-ss-primary:hover{
    background-color: var(--secondary-ss-color) !important;
    color: white !important;
    cursor: pointer;
    font-weight: 500 !important;
}

.btn-ss-secondary{
    background-color: var(--secondary-ss-color);
    color: #fff;
    cursor: pointer;
}


.btn-ss-primary-banner{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-ss-primary-banner:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-ss-white-banner{
    background-color: #fff;
    color: var(--primary-ss-color);
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    border-radius: 30px;
}

.btn-ss-white-banner:hover{
    background-color: #fdfdfd;
    opacity: 0.94;
    color: var(--primary-ss-color);
    cursor: pointer;
    padding: 1vw 2vw;
}
.home-background-banner{
    /* background-image: url('../images/home/banner.webp'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 82vh;
    width: 100%;
    position: relative;
}
.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-ss-primary:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:hover{
    background-color: var(--secondary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:focus{
    background-color: var(--secondary-ss-color) !important;
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-ss-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}
.shadow-ss-2{
    box-shadow: 2px 5px 5px 4px #dadada !important
}

.border-zk-card-bottom{
    border-bottom: 5px solid var(--primary-ss-color);
    border-left: 0;
    border-right: 0;
    border-top: 0;
}


.padding10xem{
    padding: 10em 0;
}

.padding7xem{
    padding: 7em 0;
}

.padding5xem{
    padding: 5em 0;
}

.padding2-2xem{
    padding: 2.2em 0;
}
.padding3xem{
    padding: 3em 0;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-ss-color);
    position: static;
    width: 100%;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.card-stretch{
    height: calc(100% - 1px);
}



.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: #fff;
    /* padding: 10px; */
    position: absolute;
    font-family: OpenSauceOne;
    font-weight: bolder;
    font-size: 3vw;
    top: 40%;
    letter-spacing: 1px;
    color: #000;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */




  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}

.p-top{
    padding-top: 100px;
}

.p-bottom{
    padding-bottom: 100px;
}

.section1{
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.section2{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.shadow-os{
   box-shadow: -3px 3px 4px 1px rgb(0 0 0 / 30%);
}

.icon-size{
    width: 51px;
}


.fw-900{
    font-weight: 900;
}

.fw-800{
    font-weight: 800;
}

.fw-700{
    font-weight: 700;
}


.fw-600{
    font-weight: 600;
}

.fw-500{
    font-weight: 500;
}

.fw-400{
    font-weight: 400;
}

.lh-44{
    line-height: 44px;
}

.section1 .row{
    margin-right: 0;
    margin-left: 0;
}

.btn-ss-white-secondary{
    background-color: #fff;
    border: 3px solid var(--primary-ss-color);
}
.btn-ss-white-secondary:hover{
    background-color: var(--secondary-ss-color);
    color: #fff;
}

.ls-lg-ss-aim{
    letter-spacing: 16px;
}

.provider-img{
    width: 70%;
}

.provider-bg{
    background: linear-gradient(var(--primary-ss-color) 0 50%, white 50% 100%);
    border-radius: 6%;
    padding: 40px 10px 20px 10px;
    box-shadow: 2px 3px 3px 2px #dadada !important;
    margin: 0;
}
.provider-pic-w{
    max-width: 65%;
}
.provider-sec-bg{
    background-color: #F2F5F7;
}

.provider-col-bg{
    background: white;
    border-radius: 0%;
    padding: 20px 10px 5px 10px;
    box-shadow: 1px 1px 2px 2px #dadada;
    margin: 0;
}
.pa{
    position: absolute;
    top: -450px;
}

.home-insurance-img{
    width: 75%;
}

.footer-bg{
    background-color: #d3d3d3;
}

.border-zk-secondary-footer{
    width: 100px;
    height: 3px;
    background-color: var(--secondary-ss-color);
    margin-top: 10px;
    opacity: 1;
    border: var(--secondary-ss-color);
}

.footer-logo{
    width: 250px;
}
.supernova{
    background-color: #ebebff00 !important;
}
.no-row-space .row{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.bg-none{
    background-color: rgb(0,0,0,0.1);
}

.medicatl-texting ::marker{
    color: var(--secondary-ss-color);
    font-size: 25px;
}

@media screen and (max-width: 1281px) {
    .home-marketing-section-content .fs-ss-16{
        font-size: 15px;
    }

    #website-nav .fs-ss-16{
        font-size: 15px;
    }

    
}

@media screen and (min-width: 992px ) and (max-width: 1200px ) {
    #navbar-bottom .fs-ss-16{
        font-size: 14px !important;
    }
}

@media screen and (min-width: 992px){
    .w-content{
        width: max-content !important;
    }    
}

@media screen and (max-width: 1050px){
    #website-responsive .fs-ss-92{
        font-size: 55px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    .ls-lg-ss-aim{
        letter-spacing: 10px;
    }

}

@media screen and (max-width: 991px) {
    .border-end{
        border: none !important;
    }

    .border-start{
        border: none !important;
    }
    
    .header-logo{
        width: 200px !important;
    }
    .col-serv{
        width: 30% !important;
    }
    .col-serv3{
        width: 30% !important;
    }

    .col-serv2{
        width: 40% !important;
    }

    .fs-ss-links{
        font-size: 16px !important;
    }
}

@media screen and (max-width: 991px) {
    .col-21{
        width: 54% !important ;
    }
.pattern-plans-1{
    background-image: linear-gradient(to right , #ffffff 0px,#252b37 0px) !important;
}

.pattern-plans-2{
    background-image: linear-gradient(to left , #ffffff 0px,#252b37 0px) !important;
}
    .container-ss{
        max-width: 96%;
        padding-right: 2%;
        padding-left: 2%;
        margin-right: auto;
        margin-left: auto;
    }
    .provider-bg{
        background: linear-gradient(var(--primary-ss-color) 0 30%, white 70% 100%);
    }
    .home-services-img{
        width: 120px;
    }
    .home-mobile-pic{
        width: 30%;
    }
    .logo-img{
        height:50px;
    }
    #website-responsive .minusBg10, #website-responsive .minusBg12 {
        margin-top: 0;
    }

    .home-insurance-img{
        width: 60%;
    }

    #website-responsive .fs-ss-92{
        font-size: 55px;
    }
    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 45px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:40px;
    }

    #website-responsive .fs-ss-52{
        font-size:38px;
    }


    #website-responsive .fs-ss-48{
        font-size:36px;
    }

    #website-responsive .fs-ss-40{
        font-size:32px;
    }

    #website-responsive .fs-ss-35{
        font-size:28px;
    }

    #website-responsive .padding10xem, #website-responsive .padding7xem, #website-responsive .padding5xem{
        padding: 3em 0;
    }

    #website-responsive .padding3xem{
        padding: 2em 0;
    }

    /* .btn-ss-white{
        padding: 0;
    }
    .btn-ss-white:hover{
        padding: 0;
    }
    .btn-ss-white button{
        padding: 0;
    } */
    .page-banner-title{
        top:40%;
    }
    .about-weuse-section1, .about-weuse-section2, .about-weuse-section3, .about-weuse-section4{
        width: 100%;
        height: unset;
        min-height: unset;
        display: block;
        padding: 40px 30px !important;
        font-weight: 600;
    }
    .navbar{
        padding: unset;
    }
    
    /* .home-banner-content{
        position: absolute;
        top: 15%;
        width: 50%;
        left: 3%;
    } */
}

@media screen and (max-width: 768px) { 
    .h-250{
        height: 150px !important;
    }
    .border-blue{
        border-left: none !important;
    }
    .w-65{
        width: 100% !important;
    }

    .bg-middle-banner2{
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-image: url(../images/about-us/MiddleMobile.webp) !important;
    }

    .bg-middle-banner3{
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-image: url(../images/MiddleBanner.png) !important;
    }

    .common-banner{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right !important;
    }
    
    .bg-middle-banner-plain{
        background-image: url(../images/about-us/MiddleMobile.webp) !important;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }

    .fs-ss-middle-banner2{
        font-size: 7.7vw !important;
    }

    .rounded-start{
        border-bottom: 40px !important;
        border: 0px !important;
    }

    .vh-80{
        height: 50vh !important;
    }
    .lh-para2{
        line-height: 3vh !important;
    }
    .fs-ss-sub-title2{
        font-size: 4vw !important;
        line-height: 4vh !important;
    }
    
    .fs-ss-title2{
        font-size: 9vw !important;
        letter-spacing: 0.2vw;
        line-height: 5vh !important;
    }

    .fs-ss-title2-landing-pages{
        font-size: 4vw !important;
        letter-spacing: 0.2vw;
        line-height: 4vh !important;
    }

    .fs-ss-sub-title2-landing-pages{
        font-size: 4.5vw !important;
        line-height: 4vh !important;
        font-weight: 600;
    }

    .bg-commas{
        background-position: bottom !important;
    }
    .bg-reviews{
        background-image: none !important;
        box-shadow: 2px 3px 3px 2px #dadada !important;
        padding-top: 40px;
        border-radius: 8px;
    }
    
    .fs-ss-reviews-title{
        font-size: 5vw !important;
    }
    
    .fs-ss-reviews-subtitle{
        font-size: 3vw !important;
    }
    .fs-ss-middle-banner{
        font-size: 6vw !important;
    }

   #btn .fs-ss-14{
        font-size: 12px !important;
    }

   #btn .fs-ss-16{
        font-size: 12px !important;
    }
    
    .fs-ss-sub-title{
        font-size: 4.6vw !important;
        letter-spacing: 1vw;
    }
    
    .fs-ss-title{
        font-size: 10vw !important;
        letter-spacing: 0.4vw;
        line-height: 7vh !important;
    }
    .fs-ss-64{
        font-size: 30px !important;
    }
    .rounded-start{
        border-radius: 50px !important ;
    }
    
    .banner-title2{
        font-size: 18px !important;
    }

    .lh-lg{
        line-height: 20px !important ;
    }
    
.fs-ss-16{
    font-size: 15px !important;
}
    .vh-max-content{
        height: 260px !important;
    }
    .w-icons{
        width: 40px !important;
        height: 40px !important;
    }
    
    .w-icons2{
        width: 30px !important;
        height: 40px !important;
    }
    .header-logo{
        width: 180px !important;
    }
    .fs-ss-26-links{
        font-size: 23px !important;
    }
    .title-words{
        letter-spacing: 2.5vw !important;
    }
    .w-logo-title{
        width: 50px !important;
    }
    .col-serv{
        width: 80% !important;
    }
    .col-serv3{
        width: 70% !important;
    }
    .col-serv2{
        width: 50% !important;
    }

   #weight-loss .col-serv2{
        width: 80% !important;
    }

    .footer-logo{
        width: 170px;
        margin-bottom: 20px;
    }
    .spacer-ss-25{
        height: 15px;
    }
    .home-medical-img{
        width: 60%;
    }
    .home-billing-img{
        width: 80%;
    }
    .provider-pic-w{
        max-width: 150px;
    }
    .integrated-section-img-1, .integrated-section-img-2, .integrated-section-img-3, .integrated-section-img-4{
        background-size: cover;
        background-attachment: unset;
    }
    .review-section-img-1, .review-section-img-2, .review-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }
    .appointment-section-img-1, .appointment-section-img-2, .appointment-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }

    #website-responsive .minusBg10 {
        margin-top: 0;
    }

    .home-practice-img{
        width: 40%;
    }
    .home-practice-animation{
        width: 70%;
    }

    #website-responsive .fs-ss-20{
        font-size:16px;
    }

    .home-banner-btn{
        font-size: 12px !important;
    }
    .page-banner-btn{
        margin-top: 10px;
    }
    .btn-ss-white-banner{
        font-size: 13px;
        /* margin-top: 20px; */
    }
    .btn-ss-primary-banner{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .digital-marketing-icon{
        width: 60% !important;
    }
    .about-weuse-section{
        min-height: 100% !important;
    }
    .home-healthimages{
        width: 30%;
    }

    .home-healthimages img{
        width: 30%;
    }
    .page-banner-title{
        font-size: 4vw;
        top: 40%;
        line-height: 5.5vw;
    }
    .page-banner-text1{
        font-size: 3vw;
    }
    .page-banner-desc{
        font-size: 3vw;
        font-weight: 500;
        display: none;
        line-height: 3vw;
    }
    .about-connection-sec{
        min-height: 100%;
    }
    .text-home-onestop{
        font-size: 24px !important; 
    }
    .about-ourphilosophy{
        width: 100px;
        height: 80px;
    }
    #reviews-lb .lb{
        margin-top: 7px;
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
    }

    .marginTopvitals1{
        margin-top: -110px;
    }
    
    .paddingTopvitals2{
        padding-top: 120px;
    }

    .emr_featured_col{
        /* middle */
        min-height: 0px;
    
    }
    .footer-links-section{
        padding-bottom: 0;
    }
    .ls-lg-ss-aim{
        letter-spacing: 2px;
    }

    .home-insurance-img{
        width: 50%;
    }
    .about-timing-bg{
        background: none;
    }

    #whatisccm-comprehensive .container-ss{
        max-width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-right: auto;
        margin-left: auto;

    }
    
}
  

@media screen and (max-width: 576px) {
    .w-50{
        width: 100% !important;
    }
    .w-40{
        width: 100% !important;
    }
    
    .w-60{
        width: 100% !important;
    }
    
    .bg-blue-pattern{
        background-image: none !important;
    }

    .bg-blue-pattern2{
        background-image: none !important;
    }

    #landing-page-font-primary .fs-ss-64{
        font-size: 30px !important;
    }

    #landing-page-font-primary .fs-ss-24{
        font-size: 16px !important;
    }

    #landing-page-font-primary .fs-ss-40{
        font-size: 20px !important;
    }



    #landing-page-font .fs-ss-middle-banner2{
        font-size: 25px !important;
    }
    .text-orange{
        color: white !important;
    }
    
    #font-testimonials .fs-ss-16{
        font-size: 12px !important ;
    }

    #bullets .fs-ss-12{
        font-size: 8px !important;
    }

    #landing-page-font .fs-ss-18{
        font-size: 16px !important;
    }

    #landing-page-font .fs-ss-15{
        font-size: 14px !important;
    }

    .w-icons-landing-page{
        width: 60px !important;
    }

    .bg-mobile{
        background-color :  rgb(238, 238, 238) ;
    }
    
    .banner-title{
        font-size: 8vw !important;
    }
    
    .banner-btn{
        font-size: 2vw !important;
    } 
    .col-21{
        width: 80% !important ;
    }
    .margin-form{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .pattern-plans-1{
        background-image: linear-gradient(to bottom , #ffffff 220px,#252b37 30px)!important;
    }
    
    .pattern-plans-2{
        background-image: linear-gradient(to bottom , #ffffff 220px,#252b37 30px)!important;
    }
    #belowbanner .fs-ss-26{
        font-size: 18px !important ;
        font-weight: 500 !important;
    }

    .lh-lgh{
        line-height: 25px !important;
    }
    .col-ss-four-boxes-footer{
        width: 92% !important;
    }
    .section1{
        min-height: 100%;
    }
    .section2{
        min-height: 100%;
    }
    .provider-img{
        width: 40%;
    }

      .home-services-img{
        width:120px;
      }
    .home-mobile-pic{
        width: 50%;
    }

    #website-responsive .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 35px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 35px;
    }

    #services-cta .fs-ss-92{
        font-size: 35px;
    }

    #website-responsive .fs-ss-68{
        font-size: 36px;
    }

    
    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:28px;
    }
    #website-responsive .fs-ss-52{
        font-size:30px;
    }
    
    #website-responsive .fs-ss-48{
        font-size: 24px;
    }

    #website-responsive .fs-ss-40{
        font-size: 24px;
    }

    #website-responsive .fs-ss-35{
        font-size: 22px;
    }

    
    #website-responsive .fs-ss-32{
        font-size: 24px;
    }

    #website-responsive .fs-ss-30, #website-responsive .fs-ss-28{
        font-size: 18px;
    }

    #website-responsive .fs-ss-26, #website-responsive .fs-ss-24{
        font-size: 19px;
    }

    .navbar-brand .fs-ss-26{
        font-size: 19px;
    }

    #website-responsive .fs-ss-22{
        font-size: 18px;
    }
    #website-responsive .fs-ss-18, #website-responsive .fs-ss-17{
        font-size:16px;
    }

    #btn .fs-ss-18{
        font-size:14px !important; 
    }

    #btn-landingpage .fs-ss-18{
        font-size: 16px !important; 
    }

    #mobile-font .fs-ss-32{
        font-size: 20px !important; 
    }

    #mobile-font .fs-ss-18{
        font-size: 14px !important; 
    }

    #mobile-font .fs-ss-22{
        font-size: 15px !important; 
    }

    #mobile-font-2 .fs-ss-32{
        font-size: 18px !important; 
    }

    #mobile-font-2 .fs-ss-22{
        font-size: 14px !important; 
    }

    #btn-esg .fs-ss-16{
        font-size: 20px !important; 
    }

    #real-weight-font .fs-ss-18{
        font-size: 14px !important; 
    }

    .about-health-section .fs-ss-30{
        font-size: 23px !important;
    }

    .container-ss, .container-ss2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }

    
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 440px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 400px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 280px) {
    
}

.position-header{
    position: relative !important;
}

.position-bottom-header{
    position: absolute !important;
    bottom: -27px;
}

.col-ss-four-boxes-footer{
    width: 24%;
}

.bg-ss-secondary-light {
    background-color: rgba(201, 162, 113, 0.8);
}

.bg-grey{
    background-color: #f3f3f3;
}

.lh-lgh{
    line-height: 30px;
}

.shadow-right{
    box-shadow: 6px 0px 7px 4px rgba(128, 128, 128, 0.384);
}

.z-front-page{
    z-index: 100 !important;
}

.z-back-page{
    z-index: -100 !important;
}

.fs-ss-12{
    font-size: 12px;
}

.fs-ss-7{
    font-size: 11px;
}

.middle-position-relative{
    position: relative;
}

.middle-position{
    position: absolute;
    bottom: 80px;
    left: 42%;
}

.map-style{
    filter: grayscale(100%);
}


.location-position-map{
    position: relative ;
}

.location-position{
    position: absolute ;
    top: -70px;
}

.border-2-bottom-black{
    border-bottom: 2px solid black;
}

.border-ss-secondary{
    border: 1px solid #aa8332 !important;
}

.position-img1-parent{
    position: relative;
}
.position-img2-parent{
    position: relative;
}
.position-img3-parent{
    position: relative;
}
.position-img4-parent{
    position: relative;
}
.position-img5-parent{
    position: relative;
}

.position-img1-child{
    position: absolute;
    top: -80px;
    left: -120px;
}

.position-img2-child{
    position: absolute;
    right: -120px;
}

.position-img3-child{
    position: absolute;
    left: -120px;
}

.position-img4-child{
    position: absolute;
    right: -120px;
}

.position-img5-child{
    position: absolute;
    top: -10px;
    left: -120px;
}

.w-10{
    width: 10% !important;
}

.w-15{
    width: 15% !important;
}

.w-20{
    width: 20% !important;
}

.w-65{
    width: 65%;
}

.hover-pri:hover{
    color: var(--primary-ss-color) !important;
}

.hover-green:hover{
    color: #7ea73e !important;
}

.bg-hover-green:hover{
    background-color: #7ea73e !important;
}

.hover-sec:hover{
    color: var(--secondary-ss-color) !important;
}

.position-whyus-parent{
    position: relative;
}

.position-whyus-child{
    position: absolute;
    top: -40px;
    right: -120px;
}

.pattern-plans-1{
    background-image: linear-gradient(to right , #ffffff 280px,#252b37 220px);
}

.pattern-plans-2{
    background-image: linear-gradient(to left , #ffffff 280px,#252b37 220px);
}

.hover-golden{
    color: var(--primary-ss-color) !important;
    background-color: white;
}

.hover-golden:hover{
    color: white !important;
    background-color: #404757;
}

.hover-border{
   border: 1px solid #aa8332;
   background-color: var(--primary-ss-color);
}

.hover-border:hover{
    border: 3px solid #aa8332 !important;
 }

 .hover-golden-pri{
    color: white;
    background-color: var(--secondary-ss-color);
 }

 .hover-golden-pri:hover{
    color: white;
    background-color: var(--primary-ss-color);
 }


 .hover-golden-white{
    color: white;
    background-color: var(--secondary-ss-color);
 }

 .hover-golden-white:hover{
    color: var(--primary-ss-color);
    background-color: white;
 }
.bg-pg-nmb{
    background-color: #1f2530 ;
}

.pointer-clickable{
    cursor: pointer !important;
}

.navbar-offcanvas{
    background-color: rgb(255, 255, 255);
}


.btn-close{
    background-color: white !important;
}

.shadow-os{
    box-shadow: 5px 5px 12px rgb(128, 128, 128);
}


.book-flip{
    height: 580px !important;
}

.margin-form{
    margin-left: 120px ;
    margin-right: 120px;
}

.ls-2{
    letter-spacing: 2px;
}

.form-control:focus{
    border: 1px solid #444444 !important;
    box-shadow: none !important;
    background-color: #f4f4f4 !important;
}

.w-33{
    width: 32% !important;
}

.border-brown{
    border: 5px solid #aa8332;
}

.slick-prev:before, .slick-next:before {
    color: #00000000 !important;
}

.slick-dots li button:before{
    margin-top: 20px;
    font-size: 12px !important;
}

.w-90{
    width: 90% !important;
}

.shadow-img{
    box-shadow: 3px 1px 8px rgb(167, 167, 167) ;
}

.fs-ss-links{
    font-size: 1vw;
}

.btn-primary-secondary{
    background-color: white;
    border: 2px solid var(--secondary-ss-color) !important;
    color: var(--secondary-ss-color)!important; 
}

.btn-secondary-primary{
    background-color: var(--secondary-ss-color);
}

.btn-secondary-primary{
    background-color: var(--primary-ss-color);
}

.toggle-icon:focus{
    box-shadow: none !important;
}

.btn-close{
    border: 2px solid #7ea73e !important;
    border-radius: none !important;
    margin-right: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.btn-close:focus{
    box-shadow: none !important;
}

.common-banner{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.w-logo{
    width: 50px;
}

.w-icon{
    width: 40px;
}

.w-contact-us{
    width: 30px;
}

.btn-primary-gradient{
    background-image: linear-gradient(to left, rgb(238, 231, 231) 40px, rgb(126, 167, 61) 40px);
}

.btn-white{
    background-color: rgba(255, 255, 255, 0);
    color: #ffffff;
    border: 2px solid white !important;
}

.btn-white:hover{
    background-color: white;
    color: #444444;
}

.col-21{
    width: 21% ;
}

.fs-ss-7{
    font-size: 7px;
    color: var(--primary-ss-color);
}

/* .bg-green-pattern{
    background-image: url(../images/home/BgGreen.webp);
    background-repeat: no-repeat;
    background-position: cover;
    background-position: center;
} */

.lh-para{
    line-height: 27px;
}

.lh-para2{
    line-height: 5vh;
}

.ls-h{
    letter-spacing: 2px;
}

.banner-title{
    font-size: 3.8vw;
}

.banner-title2{
    font-size: 2.0vw;
}

.banner-btn{
    font-size: 1.5vw;
}

.bg-service{
    background-color: #36acf618;
    border: 2px solid #36acf600;
}

.bg-service:hover{
    background-color: #ffffff;
    border: 2px solid var(--primary-ss-color);
    cursor: pointer;
}

.bg-service-blue{
    background-color: #eef6fd;
}

.bg-service-2:hover{
    background-color: #eef6fd;
    box-shadow: 2px 3px 3px 2px #dadada !important;
    cursor: pointer;
}

.w-services{
    width: 130px;
}

.w-services2{
    width: 150px;
}

.bg-middle-banner{
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.bg-middle-banner2{
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}

.bg-middle-banner3{
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}

.bg-middle-banner-plain{
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.title-words{
    letter-spacing: 1.5vw;
}

.w-logo-title{
    width: 90px;
}

.col-serv{
    width: 23%;
}

.col-serv3{
    width: 23%;
}

.col-serv2{
    width: 20%;
}

.bg-grey-2{
    background-color: #f1f1f1;
}

.btn-black{
    background-color: #444444;
    color: #ffffff;
}

.btn-black:hover{
    background-color: #535353;
    color: #ffffff;
}

.fs-ss-26-links{
    font-size: 26px;
}

.color-star{
    color: #fbbc04;
}

.w-icons{
    width: 50px;
    height: 50px;
}

.w-icons2{
    width: 38px;
    height: 50px;
}

.header-logo{
    width: 15vw;
}

.vh-max-content{
    height: 44vh;
}

.btn-transparent-grey:hover{
    background-color: rgb(247, 247, 247);
}

.btn-transparent{
    background-color: transparent !important;
    border: 1px solid var(--secondary-ss-color) !important;
}

.btn-orange{
    background-color: #f9d2a5 !important;
}

.btn-orange:hover{
    background-color: rgb(255, 175, 121) !important;
}

.btn-green{
    background-color: #8fb53ce3;
}

.btn-green:hover{
    background-color: #8fb53c;
}

.bg-grey2{
    background-color: #f5f5f5;
}

.border-social{
    border: 2px solid var(--secondary-ss-color);
}

.w-social-media-icons{
    width: 90px;
}

.hover1{
    display: none ;
}

.right-menu:hover .hover1{
    display: block;
}

.form-control{
    font-size: 14px ;
}

.bgweight{
    background-image: url(../images/home/WeightMeasure.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-reviews{
    background-image: url(../images/home/BgReviews.webp);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;

}

.table{
    --bs-table-bg: transparent !important ;
    --bs-table-color: var(--secondary-ss-color) !important ;
    font-weight: 600;
    border-color: var(--primary-ss-color) !important
}

#form-home .table{
    --bs-table-bg: transparent !important ;
    --bs-table-color: var(--secondary-ss-color) !important ;
    font-weight: 600;
    border-color: var(--secondary-ss-color) !important
}

.rounded-start{
    border-top-left-radius: 50px !important ;
    border-bottom-left-radius: 50px !important ;
}

.accordion{
    --bs-accordion-bg: none ; 
    --bs-accordion-border-width: none ;
    border-radius: 8px;
    margin-bottom: 8px;
}

.bg-lightblue2{
    background-color: #36acf63a !important;
}

.accordion-button:not(.collapsed) {
    background-color: #36acf670 !important;
}

.modal-header{
    border: none !important;
}

.btn-close{
    border: 1px solid var(--primary-ss-color) !important;
}

.ms-lg-8{
    margin-left: 60px;
}

.bg-lightblue{
    background-color: #f6fcff;
}

.modal-content{
    border-bottom: 7px solid var(--primary-ss-color) !important;
}

.bg-light{
    background-color: rgb(238, 238, 238) !important;
}

.bg-light2{
    background-color: rgb(243, 243, 243) !important;
}

.border-top-grey{
    border-top: 1px solid rgb(170, 170, 170) !important ;
}

.border-bottom-grey{
    border-bottom: 1px solid rgb(170, 170, 170) !important ;
}

.hover-ss-primary:hover{
    color: var(--primary-ss-color) !important;
}

.fs-ss-sub-title{
    font-size: 2vw;
    letter-spacing: 0.2vw;
}

.fs-ss-title{
    font-size: 6vw;
    letter-spacing: 0.2vw;
    line-height: 14vh;
}

.fs-ss-sub-title2{
    font-size: 1.9vw;
    line-height: 5.8vh;
}

.fs-ss-title2{
    font-size: 5.5vw;
    letter-spacing: 0.2vw;
    line-height: 12vh;
}

.border-ss-primary{
    border: 1px solid var(--primary-ss-color);
}

.border-ss-primary:hover{
    border: 1px solid var(--secondary-ss-color);
    background-color: var(--secondary-ss-color);
    color: white;
}

.hr-footer{
    border: 1px solid var(--primary-ss-color);
    opacity: 1;
    width: 110px;
}

.w-logo{
    width: 90px;
}

.w-logo2{
    width: 90px;
    height: 90px;
}

.vh-80{
    height: 70vh;
}

.position-parent{
    position: relative;
}

.position-child{
    position: absolute;
    left: 50%;
}

.position-child-2{
    position: absolute;
    top: 10% !important;
}

.fs-ss-middle-banner{
    font-size: 5vw;
}

.fs-ss-middle-banner2{
    font-size: 3.7vw;
}

.fs-ss-btn{
    font: 51vw !important;
}

.bg-primary{
    background-image: url(../images/home/BgPrimary.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fs-ss-reviews-title{
    font-size: 1.5vw;
}

.fs-ss-reviews-subtitle{
    font-size: 0.9vw;
}

.bg-commas{
    background-image: url(../images/home/BgCommas.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
}

.bg-commas2{
    background-image: url(../images/home/BgCommas2.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: left 800px;
}

.btn-book{
    font-size: 14px ;
    letter-spacing: 0.1vw;
}

.btn-book:hover{
    font-size: 14px ;
    letter-spacing: 0.1vw;
    background-color: var(--primary-ss-color) !important;
    color: var(--secondary-ss-color) !important;
    border: 1px solid var(--primary-ss-color) !important;
    cursor: pointer;
}

.bg-blue1{
    background-image: url(../images/weight-loss/ballon.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    height: 50vh;
}

.bg-blue2{
    background-image: url(../images/weight-loss/esg.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    height: 50vh;
}

.bg-digestive{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}
.bg-digestive1{
    background-image: url(../images/colonoscopy/MiddleBg.webp);
}
.bg-digestive2{
    background-image: url(../images/egd/MiddleBg.webp);
}
.bg-digestive3{
    background-image: url(../images/breath-tests/MiddleBg.webp);
}
.bg-digestive4{
    background-image: url(../images/ercp/MiddleBg.webp);
}
.bg-digestive5{
    background-image: url(../images/capsovision-endoscopy/MiddleBg.webp);
}
.bg-digestive6{
    background-image: url(../images/peg/MiddleBg.webp);
}
.bg-digestive7{
    background-image: url(../images/radio-therapy/MiddleBg.webp);
}



.bg-pink{
    background-color: #f9d2a5;
}

.btn-middle-banner{
    color: white;
}

.btn-middle-banner:hover{
    background-color: white ;
    color: var(--secondary-ss-color);
}

.btn-transparent-dark{
    color: var(--secondary-ss-color);
}


.btn-transparent-dark:hover{
    background-color: var(--secondary-ss-color) ;
    color: white !important;
}

.bg-form{
    background-image: url(../images/home/Img3.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-blue{
    background-image: url(../images/esg-landing-page/BlueBg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-orange{
    color: rgb(241, 122, 10);
}

.bg-white-hover{
    background-color: white;
    color: var(--secondary-ss-color);
}

.bg-white-hover:hover{
    background-color: var(--secondary-ss-color);
    color: white !important;
}

.bg-dollar-position-parent{
    position: relative;
}

.bg-dollar-position-child{
    position: absolute;
    top: 30%;
    left: 20%;
}

.w-60{
    width: 60%;
}

.bg-blue-pattern{
    background-image: url(../images/home/BgBluePatternFull.png);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
}

.bg-blue-pattern2{
    background-image: url(../images/home/BgBluePatternFull2.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
}

.vh-80{
    height: 80vh;
}

.vh-70{
    height: 70vh;
}

.btn-dark-transparent{
    background-color: var(--secondary-ss-color);
    color: white;
}


.btn-dark-transparent:hover{
    background-color: transparent ;
    color: var(--secondary-ss-color) !important;
}

.rounded-start-4{
    border-left: 40px;
}

.rounded-top-4{
    border-top: 40px;
}

.rounded-botom-4{
    border-bottom: 40px;
}

.btn-middle-banner2{
    background-color: white ;
    color: var(--secondary-ss-color);
}

.btn-middle-banner2:hover{
    background-color: transparent ;
    color: var(--secondary-ss-color);
}

.ltsp{
    letter-spacing: 1px;
}

.fs-ss-title2-landing-pages{
    font-size: 5.5vw;
    letter-spacing: 0.2vw;
    line-height: 12vh;
}

#orbera-landing .fs-ss-title2-landing-pages{
    font-size: 5vw;
    letter-spacing: 0.2vw;
    line-height: 12vh;
}

.fs-ss-sub-title2-landing-pages{
    font-size: 1.9vw;
    line-height: 5.8vh;
}

.bg-orange-pattern{
    background-image: url(../images/MiddleBanner.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#book-appointment .form-control:focus, .form-select:focus{
    border: 1px solid #444444 !important;
    box-shadow: none !important;
    background-color: #f4f4f4 !important;
}

.w-icons-landing-page{
    width: 80px;
}

.hr{
    opacity: 1;
    border: 2px solid var(--primary-ss-color);
    width: 40px;
    line-height: 0px;
}

.hr-underline{
    border-bottom: 2px solid var(--primary-ss-color);
    padding-bottom: 0px ;
}

.border-blue{
    border-left: 7px solid var(--primary-ss-color);
}

.w-40{
    width: 40%;
}

.w-60{
    width: 60%;
}













  
  #bmi .form {
    background-color: #fff;
    height: 240px;
    width: 450px;
    border-radius: 20px;
    margin: 20px auto 20px auto;
    display: block;
    border: solid 1px #289df6;
    
    box-shadow: 0 0 40px 0 #ddd;
  }
  
  #bmi .form:hover {
    box-shadow: 0 0 60px 0 #ccc;
    transition: .4s;
    transform: scale(1.02);
  }
  
  #bmi .row-one {
    padding: 20px;
  }
  
  #bmi .row-two {
    padding: 20px;
  }
  
  #bmi .text-input {
    width: 60px;
    height: 30px;
    border-radius: 10px;
    background-color: #dbeffe;
    border: none;
    outline: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  #bmi .text-input:last-child {
    margin-bottom: 35px;
  }
  
  #bmi .text-input:hover {
    background-color: #cbe7fd;
  }
  
  #bmi #submit {
    border: none;
    border-radius: 10px;
    height: 40px;
    width: 140px;
    background-color: #289df6;
    color: #fff;
    margin: auto;
    display: block;
    outline: none;
    cursor: pointer;
  }
  
  #bmi #submit:hover{
    background-color: #0a8ef2;
  }
  
  #bmi .text {
    display: inline-block;
    margin: 5px 20px 5px 8px;;
  }
  
  #bmi .row-one {
    padding: 30px 20px 15px 20px;
  }
  #bmi .row-two {
    padding: 15px 20px 30px 20px;
  }
  
  #bmi .container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
  }
  
  #bmi .container input {
    position: absolute;
    opacity: 0;
  }
  
  #bmi .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #dbeffe;
    border-radius: 50%;
  }
  
  #bmi .container:hover input ~ .checkmark {
    background-color: #cbe7fd;
  }
  
  #bmi .container input:checked ~ .checkmark {
    background-color: #289df6;
  }
  
  #bmi h1 {
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    color: #289df6;
    padding-top: 15px;
    display: block;
  }
  
  #bmi h2 {
    font-size: 22px;
    font-weight: 300;
    text-align: center;
  }
  
  #bmi h3 {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    padding: 15px;
  }
  
  #bmi h3 b {
    font-size: 32px;
    font-weight: 300;
    color: #289df6;
  }
  
  #bmi .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  #bmi .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  #bmi .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .ls-landing{
    letter-spacing: 0.34vw;
  }

  .ls-landing1{
    letter-spacing: 0.06vw;
  }

  .ls-landing2{
    letter-spacing: 0.2vw;
  }

.react-tabs ul{
    padding-left: 0px !important;
}

.h-250{
    height: 250px;
}

.bg-light-blog{
    background-color: #289df646;
}

.fs-ss-blog{
    font-size: 2vw;
}

.sticky-md-top{
    top: 30px;
}